<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div :style="{ 'padding-top': `${navTotalHeight}px` }">
    <v-container class="inner-container inner-container--content">
      <Form
        formClass="pa-md-10 pa-5"
        titleClass="text-4"
        :data="formData"
        :form_id="form_id"
      />
    </v-container>
  </div>
</template>
<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import detailMixins from "@/components/page/detail.js";
import Form from "@/components/dynamic/form/form.vue";
export default {
  mixins: [detailMixins],
  components: {
    Form,
  },
  data: () => ({
    pageData: null,
  }),
  computed: {
    navTotalHeight() {
      return this.$store.getters["base/navTotalHeight"];
    },
    api() {
      return this.$api.form.admin.preview;
    },
    formData() {
      if (!this.pageData) return null;
      return this.pageData;
    },
  },
  created() {
    this.$store.commit(`base/setComponentStatus`, { key: "header_menu", value: 0 });
    this.$store.commit(`base/setComponentStatus`, { key: "logo", value: 2 });
    this.$store.commit(`base/setComponentStatus`, { key: "search", value: 2 });
    this.$store.commit(`base/setComponentStatus`, { key: "location", value: 2 });
    this.$store.commit(`base/setComponentStatus`, { key: "breadcrumb", value: 0 });
    this.$store.commit(`base/setComponentStatus`, { key: "footer", value: 0 });
  },
  beforeDestroy() {
    this.$store.commit('base/resetComponentStatus')
  },
  methods: {},
};
</script>